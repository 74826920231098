<template>
  <v-container class="pa-6" fluid>
    <v-row>
      <v-col
        v-for="({ ...attrs }, i) in kpisData"
        :key="i"
        cols="12"
        md="4"
        lg="4"
      >
        <dashboard-stats-card v-bind="attrs"> </dashboard-stats-card>
      </v-col> </v-row
  ></v-container>
</template>
<script>
export default {
  components: {
    DashboardStatsCard: () =>
      import("@/components/core/cards/DashboardStatsCard")
  },
  name: "KpiList",
  props: {
    kpis: {
      type: [Array, Object],
      required: true
    }
  },
  data() {
    return {
      kpisSettings: [
        {
          key: "fill_rate",
          key2: "rate_percentage",
          color: "primary",
          ttitle: "Fill rate",
          icon: "mdi-check-decagram",
          subtitle: "",
          tooltip:
            "Fill rate = Workforce requests filled / Total number of workforce requests"
        },
        {
          key: "drop_out_rate",
          key2: "rate_percentage",
          color: "orange",
          ttitle: "Personnel dropout rate",
          icon: "mdi-arrow-bottom-right",
          subtitle: "",
          tooltip:
            "Personnel dropout rate = Workforce volumes not appearing for a job / Confirmed workforce"
        },
        {
          key: "dismissed_by_client_rate",
          key2: "rate_percentage",
          color: "green darken-2",
          icon: "mdi-handshake-outline",
          ttitle: "Client dismissal rate",
          subtitle: "",
          tooltip:
            "Client dismissal rate = Workforce volume dismissed by BMW Supplier / Total workforce provided"
        },
        {
          key: "premature_termination_rate",
          key2: "rate_percentage",
          color: "pink",
          ttitle: "Premature termination",
          tooltip:
            "Premature termination rate = Workforce volume quiting during first 10 days / Total workforce provided",
          icon: "mdi-clipboard-text-off",
          subtitle: ""
        },
        {
          key: "sickness_rate",
          key2: "rate_percentage",
          color: "indigo darken-2",
          icon: "mdi-emoticon-sick",
          ttitle: "Sickness rate",
          subtitle: "",
          tooltip:
            "Sickness rate = unproductive hours due to illness / total hours delivered"
        },
        {
          key: "total_occupational_accidents",
          key2: null,
          color: "red darken-2",
          icon: "mdi-tools",
          ttitle: "Accidents",
          subtitle: "",
          tooltip:
            "No. of occupational accidents = total number of occupational accidents"
        }
      ]
    };
  },
  computed: {
    kpisData() {
      const newValues = this.kpisSettings.map(item => {
        let value = this.kpis[item.key];
        value = item.key2 && value ? value[item.key2] : value;
        value = value ? value : "N/A";
        item.subtitle = value;
        return item;
      });
      return newValues;
    }
  },
  created() {},

  methods: {}
};
</script>
